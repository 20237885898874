@color-light: #0088cc;
@color-menu-light: #0375b8;
@color-dark: #112766;

.gradient-tb(@startColor, @endColor) {

  background-image: -webkit-linear-gradient(to bottom, @startColor, @endColor);
  background-image: -moz-linear-gradient(to bottom, @startColor, @endColor);
  background-image: -ms-linear-gradient(to bottom, @startColor, @endColor);
  background-image: -o-linear-gradient(to bottom, @startColor, @endColor);
  background-image: linear-gradient(to bottom, @startColor, @endColor);
}

.page_header {

  .logo {

    .logo_header {
      background-image: url(/images/logo-dark.png);
      //background-image: url(/images/logo_20let_blue.png);
      top: -65px;
      position: relative;

      @media (max-width: 1200px) {
        // средние десктопы и ниже
        background-image: url(/images/logo-dark.png);
        //background-image: url(/images/logo_20let_mini.png);
        top: -65px;
      }

      @media (max-width: 990px) {
        // средние десктопы и ниже
        background-image: url(/images/logo-dark.png);
        top: 0px;
      }

    }
  }

  .address_plate {
    //background-image: url("/images/header-background-dark.svg");
    background-image: url('/images/header-background-dark-v2.svg');
  }
}

.navbar-default {

  //background-image: none;
  //background-color: #112E47;
  background-color: @color-dark;
  //background-image: linear-gradient(to bottom, @color-dark 0px, @color-light 100%);

  .gradient-tb(@color-dark 0px, @color-light 100%);

  border-bottom: 3px solid @color-light;

  .navbar-nav {

    li {

      &.open {
        background-color: @color-menu-light !important;
      }
    }

    .dropdown-menu {

      background-color: @color-menu-light !important;

      li {
        padding: 4px 20px 2px 20px;
        margin: 0;
        border-right: 1px solid @color-dark;

        &.open {
          background-color: @color-menu-light !important;
        }
      }
    }

    li {
      border-right: 1px solid @color-dark;
    }

    li p {
      color: #000000;
      text-shadow: unset;
    }

  }
}

.nav-left .accordion a {
  color: #4c4c4c !important;
}

.nav-left {
  .left-menu-header {
    background-image: url("/images/block-arrow-dark.png") !important;
  }
}

#modalMapBox .modal-dialog .modal-content .modal-body h2 {

  background-image: url("/images/block-arrow-dark.png") !important;
}

.breadcrumbs {

  background-image: url("/images/icons/breadcrumbs-dark.png") !important;

  ul li.active {
    color: #4c4c4c !important;
  }
  a {
    color: #4c4c4c !important;
  }
}

a, h1, h2, h3, h4 {

  color: @color-dark;
}

a:hover, a:active {

  color: @color-light;
}

.page-text table thead th, .btn-primary {

  background-image: none;
  background-color: @color-dark;
  .gradient-tb(@color-dark, @color-light);

}

.btn-primary:hover {

  background-image: none;
  background-color: @color-light;
}

#page-footer, .page_header {

  color: @color-dark !important;
}

.availability-indicator {

  span {

    &.label {
      border: 1px @color-dark solid;
    }

    &.label-info {

      background-color: @color-dark;
    }
  }
}

